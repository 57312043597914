'use strict';

import {IScope, IRootScopeService, ILogService} from "angular";
import {UserAccount, Settings, ChangePasswordMode, EFE2Features, EFieldSyncType, AMobileObjectTab, FidoRegistration, VehicleTrackingDisplayMode} from "../../../../data/account.data";
import RestService from "../../../../services/rest.service";
import * as webauthnJson from "@github/webauthn-json";
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import HelperService from "../../../../services/helper.service";
import angular = require("angular");

const MAX_UPLOAD_SIZE = 500 * 1000; // 500 KB

require('./settings.modal.scss');

/* @ngInject */
export default class SettingsInstanceModalController {
  public $uibModal: any;
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $log: ILogService;
  public $http: angular.IHttpService;
  public uploader: any;
  public $uibModalInstance: any;
  public $translate: any;
  public restService: RestService;
  public dataService: any;
  public Notification: any;
  public account: UserAccount;
  public settings: Settings;
  public helperService: HelperService;
  public isWasserkarteTokenValid: any = undefined;
  public notTested: boolean = true;
  public isGeocoding: boolean = false;
  public isLoading: boolean = false;
  public hasTracking: boolean =false;
  public pw: PasswordChangeData;
  public randomPassword: string;
  public showPasswordMap: Map<string, boolean> = new Map<string, boolean>();
  public roadblockAccesses: any;
  public logo: string;
  public mode: SettingsMode = SettingsMode.PASSWORD;
  public changePasswordMode: ChangePasswordMode = ChangePasswordMode.AMOBILE_PRO;
  public allTabs = Object.keys(AMobileObjectTab);
  public trackingDisplayModes = Object.keys(VehicleTrackingDisplayMode);

  /* the button should only be shown if the backend has valid credentials, thus we only update it on initial load and save */
  public showIvenaMappingButton = false;

  public fidoSupported = false;
  public isLoadingFido = false;
  public fidoRegistrations: FidoRegistration[] = [];


  constructor($scope: IScope, helperService: HelperService, $uibModal, $http: angular.IHttpService, $rootScope: IRootScopeService, $log: ILogService, $uibModalInstance, $translate, restService: RestService, dataService, Notification, settingsTab: SettingsMode,
    public privilegeService: PrivilegeService) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.$http = $http;
    this.$uibModalInstance = $uibModalInstance;
    this.$translate = $translate;
    this.helperService = helperService;
    this.restService = restService;
    this.dataService = dataService;
    this.Notification = Notification;

    this.account = this.dataService.getAccount();
    this.settings = this.account.settings;
    this.uploader = this.restService.createLogoUploader();
    this.fidoSupported = webauthnJson.supported();

    if (settingsTab) {
      this.mode = settingsTab;
    } else {
      if (this.privilegeService.has(RolePrivilege.Settings_ChangePassword)) {
        this.mode = SettingsMode.PASSWORD;
      } else if(this.privilegeService.has(RolePrivilege.Settings_Tracking_Sinks)){
        this.mode = SettingsMode.SINKS;
      } else {
        this.mode = SettingsMode.AMOBILEPRO;
      }
    }

    this.loadLogo();
    this.loadAllFidoRegistrations();

    this.uploader.headers = {
      Authorization: this.$http.defaults.headers.common.Authorization
    }
    this.uploader.url = this.restService.getBaseUrl() + '/account/logo';

    this.uploader.onCompleteItem = () => {
      this.$uibModalInstance.close();
    };

    this.uploader.onAfterAddingFile = (element) => {
      const size = element.file.size;
      if (size > MAX_UPLOAD_SIZE) {
        this.$translate(['MAIN.SETTINGS.FILE_TOO_LARGE_TITLE', 'MAIN.SETTINGS.FILE_TOO_LARGE_TEXT']).then((translations) => {
          this.Notification.error({
            message: translations['MAIN.SETTINGS.FILE_TOO_LARGE_TEXT'],
            title: translations['MAIN.SETTINGS.FILE_TOO_LARGE_TITLE']
          });
        });
        this.uploader.clearQueue();
        return;
      }
      this.isLoading = true;
      this.uploader.uploadAll();
    }

    this.uploader.onCompleteAll = () => {
      this.isLoading = false;
      this.$rootScope.$emit('logo.updated');
    };

    this.isWasserkarteTokenValid = undefined;
    this.pw = {
      currentPw: '',
      newPw: '',
      newPwRepeated: '',
      changeAllowed: false
    } as PasswordChangeData;
    this.roadblockAccesses = this.dataService.getRoadblockAccesses();
    this.hasTracking= this.account.features.includes(EFE2Features.TRACKING);


    this.$scope.$watch('ctrl.settings.settingsSyncEnabled', ()=>{
      if (!this.settings.settingsSyncEnabled && this.settings.settingsSyncEnabledAlarm) {
        this.settings.settingsSyncEnabledAlarm = false;
        this.$scope.$applyAsync();
      }
      if (!this.settings.settingsSyncEnabled && this.settings.settingsSyncEnabledTracking){
        this.settings.settingsSyncEnabledTracking = false;
        this.$scope.$applyAsync();
      }
    });

    const tmp = [];
    this.settings.amobileObjectTabs.forEach(entry => {
      tmp.push(entry);
    });
    this.allTabs.forEach(entry => {
      if (tmp.includes(entry)) return;
      tmp.push(entry);
    });
    this.allTabs = tmp;

    this.showIvenaMappingButton = this.areIvenaCredentialsNotBlank();

  }

  /**
   * Test wasserkarte.info token
   */
  testToken() {
    if (angular.isUndefined(this.settings.wasserkarteInfoToken) || this.settings.wasserkarteInfoToken === '') {
      this.isWasserkarteTokenValid = 'false';
      return;
    }

    var tokenToCheck = this.settings.wasserkarteInfoToken;

    if (tokenToCheck.match(/^x+$/)) {
      tokenToCheck = undefined;
    }

    this.isLoading = true;
    this.restService.isWasserkarteTokenValid(tokenToCheck, () => {
      this.isWasserkarteTokenValid = 'true';
      this.isLoading = false;
      this.notTested = false;
    }, (errorResponse) => {
      //Error occured
      this.isWasserkarteTokenValid = 'false';
      this.isLoading = false;
      this.notTested = false;
      this.$log.error(errorResponse);
    })
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  /**
   * Geocode the current address
   */
  geocode() {
    var location = '';
    if (angular.isDefined(this.settings.street)) {
      location = this.settings.street;
    } else {
      return;
    }

    if (angular.isDefined(this.settings.house)) {
      location = location + ' ' + this.settings.house;
    }

    if (angular.isDefined(this.settings.postalCode)) {
      location = location + ' ' + this.settings.postalCode;
    }

    if (angular.isDefined(this.settings.city)) {
      location = location + ' ' + this.settings.city;
    } else {
      return;
    }

    location = location.trim();
    if (location === '') {
      return;
    }

    this.$log.debug('Geocoding: ' + location);
    this.isGeocoding = true;
    this.restService.geocode(location, (response) => {
      this.isGeocoding = false;
      this.$log.debug(response.data);
      this.settings.lat = response.data.latitude;
      this.settings.lng = response.data.longitude;
    }, (errorResponse) => {
      this.isGeocoding = false;
      this.$log.error(errorResponse);
    });
  };


  /**
   * Change the applications password
   */
  changeAppPw() {
    if (!this.privilegeService.has(RolePrivilege.Settings_ChangeAppPassword)) {
      return;
    }
    // Change users or persons password
    this.restService.changeAppPassword(this.randomPassword, this.changePasswordMode).then(() => {
      this.randomPassword = undefined;
      this.$scope.$applyAsync();
    });
  }


  /**
   * Change the users password
   */
  changePw() {
    if (!this.privilegeService.has(RolePrivilege.Settings_ChangePassword)) {
      return;
    }
    if (this.pw.newPw === '' || this.pw.newPwRepeated === '') {
      //Password is empty
      this.$translate(['SETTINGS.PW_ERROR_TITLE', 'SETTINGS.PW_EMPTY']).then((translations) => {
        this.Notification.error({
          message: translations['SETTINGS.PW_EMPTY'],
          title: translations['SETTINGS.PW_ERROR_TITLE']
        });
      });
      return;
    }

    if (this.pw.newPw !== this.pw.newPwRepeated) {
      //Password is not equal
      this.$translate(['SETTINGS.PW_ERROR_TITLE', 'SETTINGS.PW_NOT_EQUAL']).then((translations) => {
        this.Notification.error({
          message: translations['SETTINGS.PW_NOT_EQUAL'],
          title: translations['SETTINGS.PW_ERROR_TITLE']
        });
      });
      return;
    }

    this.isLoading = true;
    this.dataService.changePassword(this.pw.currentPw, this.pw.newPw, () => {
      this.isLoading = false;
      this.$translate(['SETTINGS.PW_OK', 'SETTINGS.PW_CHANGED']).then((translations) => {
        this.Notification.success({
          message: translations['SETTINGS.PW_CHANGED'],
          title: translations['SETTINGS.PW_OK']
        });
      });
    }, (errorResponse) => {
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  }

  toggleShowPassword(key: string) {
    this.showPasswordMap.set(key, !this.showPassword(key));
  }

  showPassword(key: string) {
    return this.showPasswordMap.get(key) ?? false;
  }

  isPasswordComplexEnough(): boolean {
    if (!this.newPasswordHasLowercaseCharacter()) {
      return false;
    }
    if (!this.newPasswordHasUppercaseCharacter()) {
      return false;
    }
    if (!this.newPasswordHasDigit()) {
      return false;
    }
    if (!this.newPasswordHasSpecialChar()) {
      return false;
    }
    return this.newPasswordIsLongEnough();
  }

  newPasswordIsLongEnough(): boolean {
    return this.pw.newPw.length >= 10;
  }

  newPasswordHasSpecialChar(): boolean {
    return /[!@#$%&*()_+=|<>?{}\[\]~-]/.test(this.pw.newPw);
  }

  newPasswordHasDigit(): boolean {
    return /\d/.test(this.pw.newPw);
  }

  newPasswordHasUppercaseCharacter(): boolean {
    return /[A-Z]/.test(this.pw.newPw);
  }

  newPasswordHasLowercaseCharacter(): boolean {
    return /[a-z]/.test(this.pw.newPw);
  }

  passwordsMatch(): boolean {
    return this.pw.newPw === this.pw.newPwRepeated;
  }

  generatePassword() {
    this.randomPassword = this.helperService.generatePassword(12);
    return this.randomPassword;
  }

  generateApiKey(): string {
    return Array(32).fill("01234567890abcdef").map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('');
  }

  loadLogo() {
    this.restService.getLogo().then(logo => this.logo = logo);
  }

  /**
   * This is triggerd if any of the password field inputs had been changed
   */
  pwInputChanged() {
    this.pw.changeAllowed = false;
    if (this.pw.currentPw === '') {
      // Current PW is empty
      return;
    }
    if (this.pw.newPw === '') {
      // New PW is empty
      return;
    }
    if (this.pw.newPwRepeated === '') {
      // New repeated PW is empty
      return;
    }

    if (this.pw.newPwRepeated !== this.pw.newPw) {
      // New pws does not match
      return;
    }
    this.pw.changeAllowed = true;
  }

  ok() {
    this.isLoading = true;
    if (this.settings.smsflatrateApiKey && this.settings.smsflatrateApiKey !== '') {
      // Deactivate warning
      this.account.customerSmsApiKeyEnforcedWarning = false;
    }

    const tmp = [];
    this.allTabs.forEach(entry => {
      if (this.settings.amobileObjectTabs.includes(entry as AMobileObjectTab)) {
        tmp.push(entry);
      }
    });
    this.settings.amobileObjectTabs = tmp;

    this.dataService.updateUserSettings(this.settings, () => {
      this.isLoading = false;
      this.dataService.setSettings(this.settings);
      this.showIvenaMappingButton = this.areIvenaCredentialsNotBlank();
    }, (errorResponse) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(errorResponse);
    });
  }

  getClassForSyncSetting(settings: EFieldSyncType): string {
    if (!settings) {
      return 'btn-default';
    }
    switch (settings) {
      case EFieldSyncType.ALWAYS:
        return 'btn-success';
      case EFieldSyncType.INITIAL:
        return 'btn-primary';
      case EFieldSyncType.NEVER:
        return 'btn-default';
    }
  }

  isTabSelected(tab: AMobileObjectTab) {
    let list = this.settings.amobileObjectTabs.filter(t => t === tab);
    return list.length > 0;
  }


  changeStateForTab(tab: AMobileObjectTab) {
    var index: number = this.settings.amobileObjectTabs.indexOf(tab, 0);
    if(index > -1) {
      this.settings.amobileObjectTabs.splice(index, 1);
    } else {
      this.settings.amobileObjectTabs.push(tab);
    }
  }

  /**
   * Move AMobileObjectTab a tab up
   * @param index
   */
  moveUp(index: number) {
    this.move(this.allTabs, index, index - 1);
  }

  /**
 * Move AMobileObjectTab a tab down
 * @param index
 */
  moveDown(index: number) {
    this.move(this.allTabs, index, index + 1);
  }

  move(input, from: number, to: number) {
    let numberOfDeletedElm = 1;

    const elm = input.splice(from, numberOfDeletedElm)[0];

    numberOfDeletedElm = 0;

    input.splice(to, numberOfDeletedElm, elm);
  }

  registerFido(): void {
    this.isLoadingFido = true;
    this.restService.fidoRegister().then(async result => {
      this.$scope.$applyAsync();
      const promise = webauthnJson.create(result as webauthnJson.CredentialCreationOptionsJSON);
      promise.then((publicKeyCredential) => {

        this.restService.fidoFinishRegistration(publicKeyCredential).then(() => {
          this.account.hasFidoSecureData = true;
          this.isLoadingFido = false;
          this.loadAllFidoRegistrations();
          this.$scope.$applyAsync();
        }).catch(err => {
          this.$translate(['MAIN.SETTINGS.FIDO.ERROR_TITLE']).then((translations) => {
            this.Notification.error({
              message: err.data.message,
              title: translations['MAIN.SETTINGS.FIDO.ERROR_TITLE']
            });
          });
          this.isLoadingFido = false;
          this.$scope.$applyAsync();
        });
      }).catch(fidoError => {
        this.$translate(['MAIN.SETTINGS.FIDO.ERROR_TITLE']).then((translations) => {
          this.Notification.error({
            message: 'Fehler: ' + fidoError,
            title: translations['MAIN.SETTINGS.FIDO.ERROR_TITLE']
          });
        });
        this.isLoadingFido = false;
        this.$scope.$applyAsync();
      });
    });
  }

  /**
   * Delete an existing FIDO registration
   * @param registration
   */
  deleteFidoRegistration(registration: FidoRegistration) {
    this.isLoadingFido = true;
    this.restService.deleteFidoRegistraion(registration).then(() => {
      this.loadAllFidoRegistrations();
    }).finally(() => {
      this.isLoadingFido = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Update an existing FIDO registration
   * @param registration
   */
  updateFidoRegistration(registration: FidoRegistration) {
    this.isLoadingFido = true;
    this.restService.updateFidoRegistraion(registration).then(() => {
      this.loadAllFidoRegistrations();
    }).finally(() => {
      this.isLoadingFido = false;
      this.$scope.$applyAsync();
    });
  }

  /**
   * Get all FIDO registrations
   */
  loadAllFidoRegistrations() {
    this.isLoadingFido = true;
    this.restService.getAllFidoRegistraions().then((result) => {
      this.fidoRegistrations = result;
    }).finally(() => {
      this.isLoadingFido = false;
      this.$scope.$applyAsync();
    });
  }

  changeAlias(entry: FidoRegistration): void {
    this.$uibModal.open({
      template: require('../change.string.modal/change.string.modal.html'),
      controller: 'ChangeStringModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'md',
      resolve: {
        str: () => {
          return entry.alias;
        },
        okFunction: () => {
          return (newAlias: string) => {
            if (newAlias === '') return;
            entry.alias = newAlias;
            this.isLoadingFido = true;
            this.restService.updateFidoRegistraion(entry).then(() => {
              this.loadAllFidoRegistrations();
            });
          }
        }
      }
    });
  }

  selectOnMap() {
    //let user select coordinates
    this.$uibModal.open({
      template: require('../../../modals/alarms/choose.on.map.modal/choose.on.map.modal.html'),
      controller: 'ChooseAlarmOnMapController',
      controllerAs: 'ctrl',
      size: 'lg',
      resolve: {
        coords: () => {
          return {
            lat: this.settings.lat,
            lng: this.settings.lng
          }
        },
        position: () => {
          return undefined;
        },
        okFunction: () => {
          return (coords) => {
            this.settings.lat = coords.lat;
            this.settings.lng = coords.lng;
          }
        }
      }
    });
  }

  private areIvenaCredentialsNotBlank(): boolean {
    return this.settings.ivenaCredentials.username 
      && this.settings.ivenaCredentials.username.trim().length > 0
      && this.settings.ivenaCredentials.password
      && this.settings.ivenaCredentials.password.trim().length > 0;
  }

  public openIvenaMappingModal() {
    this.$uibModal.open({
      template: require('../ivena.mapping.modal/ivena.mapping.modal.html'),
      controller: 'IvenaMappingModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {
      }
    });
    
  }
}

interface PasswordChangeData {
  currentPw: string,
  newPw: string,
  newPwRepeated: string,
  changeAllowed: boolean,
  newAppPassword: string
}

export enum SettingsMode {
  PASSWORD = 'PASSWORD',
  SHIFTBOOK = 'SHIFTBOOK',
  MISC = 'MISC',
  AMOBILEPRO = 'AMOBILEPRO',
  SETTINGS_SYNC = 'SETTINGS_SYNC',
  AMOBILEPRO_TABS = 'AMOBILEPRO_TABS',
  AMOBILEPRO_LAYERS = 'AMOBILEPRO_LAYERS',
  PRIVACY = 'PRIVACY',
  FIDO = 'FIDO',
  HOLIDAYS = 'HOLIDAYS',
  SINKS = 'SINKS',
  MISSION_EXPORTER = 'MISSION_EXPORTER'
}
