'use strict';

/* @ngInject */
import angular from "angular";

export default class DataService {

  constructor($log, $rootScope, $state, restService, privilegeService) {
    $log.debug('Init DataService');
    this.$log = $log;
    this.account = undefined;
    this.$state = $state;
    this.restService = restService;
    this.priv = privilegeService;
    this.$rootScope = $rootScope;
    this.roadblockTypes = ['ROAD_WORKS', 'ROAD_CLOSED', 'INCIDENT'];
    this.roadblockStates = ['PLANNED', 'ACTIVE', 'PASSED'];
    this.roadblockAccesses = ['PUBLIC_READ', 'PUBLIC_WRITE', 'PRIVATE'];
    this.vehicleFaultStates = ['ACTIVE', 'ACTIVE_PROGRESS', 'ELIMINATED'];
    this.roadblockMapTripTypes = ['BLOCK', 'AVOID'];
    this.roadblockMapTripDirections = ['ATOB', 'BTOA', 'BOTH'];

    //Alarm
    this.alarms = {
      data: undefined,
      dateEnd: undefined,
      dateStart: undefined,
      searchFilter: undefined,
      limit: undefined
    };

    //Availability
    this.availability = {
      data: undefined,
      rules: undefined,
      apiKeys: undefined,
      onlineservices: undefined,
      future: {},
      futureDate: undefined
    };

    //Vehicles
    this.vehicles = {
      data: undefined
    };

    this.vehiclesPaginated = {
      data: undefined
    }

    //Parking Positions
    this.parkingPositions = {
      data: undefined
    };

    //Relais
    this.relais = {
      data: undefined
    };


    //AlarmGroups
    this.alarmGroups = {
      data: undefined
    };

    //Plugins
    this.plugins = {
      data: undefined
    };

    //Plugins
    this.events = {
      data: undefined,
      range: undefined
    };

    //Templates
    this.templates = {
      pipeline: undefined,
      plugins: undefined,
      alarms: undefined,
      grid: undefined
    };

    //Provisioning
    this.provisioning = {
      data: undefined
    };

    //AlarmObjects
    this.alarmObjects = {
      data: undefined,
      ownObjects: 0,
      totalObjects: 0,
      sharedObjects: 0
    };

    //Roadblocks
    this.roadblocks = {
      data: undefined
    };

    //Image assets
    this.assets = {
      data: undefined
    };

    // POI
    this.pois = {
      data: undefined
    };
  }


  /**
   * Logout and delete token
   */
  logout() {
    this.$log.debug('Logging off...');
    this.restService.deleteToken();
    this.alarms = {
      data: undefined,
      dateEnd: undefined,
      dateStart: undefined,
      searchFilter: undefined
    };
    this.account = undefined;
    this.availability = {
      data: undefined,
      rules: undefined,
      apiKeys: undefined,
      onlineservices: undefined,
      future: {}
    };
    this.vehicles = {
      data: undefined,
      mapping: undefined
    };

    this.vehiclesPaginated = {
      data: undefined,
      mapping: undefined
    }
    this.parkingPositions = {
      data: undefined
    };
    this.relais = {
      data: undefined
    };
    this.alarmGroups = {
      data: undefined
    };
    this.plugins = undefined;

    this.templates = {
      pipeline: undefined,
      plugins: undefined,
      alarms: undefined
    };
    this.provisioning = {
      data: undefined
    };
    this.alarmObjects = {
      data: undefined
    };
    this.roadblocks = {
      data: undefined
    };
    this.assets = {
      data: undefined
    };
    this.pois = {
      data: undefined
    };
    this.shiftbookCategories = undefined;
    this.shiftbookCustomFields = undefined;
    this.shiftbookRoadblocks = undefined;

    this.layers = [];


    this.$log.debug('Trigger logout event...');
    this.$rootScope.$broadcast('delete.account');
  };

  /**
   * Get the logged in users account
   */
  getAccount() {
    return this.account;
  }

  setSettings(settings) {
    if (angular.isDefined(this.account)) {
      this.account.settings = settings;
      this.$rootScope.$broadcast('new.settings', settings);
    }
  }

  hasAccount() {
    return angular.isDefined(this.account);
  }

  /**
   * Set logged in users account
   */
  setAccount(newAccount) {
    this.$log.debug('Set logged in users account');
    this.account = newAccount;
    this.priv.setAccount(this.account);
    //Trigger event
    this.$rootScope.$broadcast('new.account', newAccount);
    // Set document title
    document.title = this.account.instanceTitle;
  };

  loadAccount(loggedInFromAdmin,success, error) {
    var self = this;
    //Filter alarms
    this.restService.loadAccount(loggedInFromAdmin,(response) => {
      //Success
      self.setAccount(response.data);
      success(response.data);
    }, (response) => {
      //Failed
      error(response);
    });
  };

  /**
   * Returns true, if user is logged in and account is loaded
   */
  isLoggedIn(success, error) {
    var hasAccount = angular.isDefined(this.account);
    if (hasAccount) {
      success(this.account);
      return;
    }
    var token = this.restService.loadToken();
    var loggedInFromAdmin = this.restService.getLoggedInFromAdmin();
    if (angular.isDefined(token) && token !== null) {
      this.$log.debug('Token exists, loading account');
      this.loadAccount(loggedInFromAdmin,(account) => {
        this.$log.info('Account loaded');
        success(account);
      }, () => {
        this.restService.deleteToken();
        this.$log.error('Could not load account');
        error(false);
      });
    } else {
      error(false);
    }
  };

  //TODO
  selectPageForPrivilege() {
    // if person does not have home privilege, check the permissions and get the first one that matches
    if (!this.account) {
      this.$state.go('wache');
    }

    this.$log.debug('Changing page...');
    if (this.account.role.privileges['Addressbook']) return 'users';
    else if (this.account.role.privileges['Station']) return 'wache';
    else if (this.account.role.privileges['Units']) return 'units';
    else if (this.account.role.privileges['Alarm']) return 'alarm';
    else if (this.account.role.privileges['Objects']) return 'objects';
    else if (this.account.role.privileges['Availability']) return 'availability';
    else if (this.account.role.privileges['EventPlanning_Appointment']) return 'calendar';
    else if (this.account.role.privileges['Statistics']) return 'statistic';
    else this.$state.go('wache'); //use wache as fallback because the sensor data does not need any permission
  }


  getAlarmParams() {
    return this.alarms;
  };

  //#### ALARMS ####


  /**
   * Update state of current alarm
   */
  updateAlarmState(alarm, state, success, error) {
    this.restService.updateAlarmState(alarm.id, state,
      (response) => {
        //Success > update state in local object
        alarm.state = response.data.state;
        alarm.protocol = response.data.protocol;
        delete alarm.parameters.timeToClose;
        alarm.pushAlarmSummaryResponse = response.data.pushAlarmSummaryResponse;
        alarm.hasPushAlarmSummaryResponse = angular.isDefined(alarm.pushAlarmSummaryResponse);
        alarm.hasState = true;
        alarm.hasProtocol = true;
        alarm.hasCloseActions = false;
        success(alarm);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Update the alarms important state
   * @param {} alarm
   * @param {*} isImportant
   * @param {*} success
   * @param {*} error
   */
  updateAlarmImportantState(alarm, isImportant, success, error) {
    var data = {
      important: isImportant
    };
    this.restService.patchAlarm(alarm.id, data,
      (response) => {
        alarm.important = isImportant;
        success(alarm);
      },
      (response) => {
        //Failed
        error(response);
      });
  }

  /**
   * Delete a single alarm from server
   */
  deleteAlarm(alarm, success, error) {
      this.restService.deleteAlarm(alarm.id, () => {
            this.$rootScope.$emit('single.alarm.deleted', alarm);
        success(this.alarms.data);
      }, (response) => {
        error(response)
      });
  };

  /**
   * Delete all alarms from server
   */
  deleteAlarms(username, dayRange, success, error) {
    this.restService.deleteAlarms(username, dayRange,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  //#### AVAILABILITY ####

  /**
   * Load onlineservices from server
   */
  getOnlineServices(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.availability.onlineservices)) {
      this.$log.debug('Loading onlineservices from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadOnlineServices(success, error);
    } else {
      success(this.availability.onlineservices);
    }
  };

  /**
   * Fetch onlineservices from server
   */
  loadOnlineServices(success, error) {
    this.restService.loadOnlineServices(
      (response) => {
        //Success
        this.availability.onlineservices = response;
        this.$log.info(this.availability.onlineservices);
        success(this.availability.onlineservices);
      },
      (response) => {
        //Failed
        this.availability.onlineservices = undefined;
        error(response);
      });
  };

  /**
   * Load availability from server
   */
  getAvailability(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.availability.data)) {
      fetchFromServer = true;
      this.$log.debug('Fetching from server, because availability does not exist in local cache');
    }
    if (fetchFromServer) {
      //Load
      this.loadAvailability(success, error);
    } else {
      success(this.availability);
    }
  };

  /**
   * Fetch availability from server
   */
  loadAvailability(success, error) {
    this.$log.debug('Loading availability from server for first time...');
    this.restService.loadAvailability((response) => {
      //Success
      this.availability = response;
      // Rules updated
      this.$rootScope.$emit('rules.updated', this.availability.rules);
      success(this.availability);
    }, (response) => {
      //Failed
      this.availability.data = undefined;
      error(response);
    });
  }

  /**
   * Delete an existing Online Service Person
   * @param {*} person
   * @param {*} success
   * @param {*} error
   */
  deleteOnlineServicePerson(person, success, error) {
    this.$log.debug('Deleting online service person: ' + person.name);
    this.restService.deleteOnlineServicePerson(person, () => {
      success();
    }, (response) => {
      //Failed
      error(response);
    });
  }


  /**
   * Filter availability
   */
  filterAvailability(filterSettings, success) {
    //Filter
    var filteredData = {
      lstOfAvailabilities: [],
      functions: [],
      statistic: {},
      groups: [],
      name: ''
    };

    if (angular.isUndefined(this.availability.data)) {
      success(filteredData);
      return;
    }

    //Re-Use old data
    filteredData.functions = this.availability.data.functions;
    filteredData.groups = this.availability.data.groups;
    filteredData.name = this.availability.data.name;

    filteredData.lstOfAvailabilities = this.availability.data.lstOfAvailabilities.filter((person) => {
      if (filterSettings.hidePersonsWithoutAvailability) {
        if (person.state === 'NONE') {
          return false;
        }
      } else {
        return true;
      }
      return true;
    });
    success(filteredData);
  };

  //#### PROVISIONING ####

  /**
   * Get all provisioning configurations
   */
  getProvisionings(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.provisioning.data)) {
      this.$log.debug('Loading provisioning from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadProvisioning(success, error);
    } else {
      success(this.provisioning.data);
    }
  };

  /**
   * Fetch provisioning from server
   */
  loadProvisioning(success, error) {
    this.restService.loadProvisioning(
      (response) => {
        //Success
        this.provisioning.data = response.data;
        success(this.provisioning.data);
      },
      (response) => {
        //Failed
        this.provisioning.data = undefined;
        error(response);
      });
  };

  /**
   * Fetch provisioning from server
   */
  getSingleProvisioning(id, success, error) {
    this.restService.getSingleProvisioning(id,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  /**
   * Create provisioning on server
   */
  createProvisioning(success, error) {
    this.restService.createProvisioning(
      (response) => {
        //Success
        if (angular.isUndefined(this.provisioning.data)) {
          this.provisioning.data = [];
        }
        this.provisioning.data.push(response.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Duplicate a existing provisioning profile
   */
  duplicateProvisioning(prov, success, error) {
    this.restService.duplicateProvisioning(prov.id,
      (response) => {
        //Success
        if (angular.isUndefined(this.provisioning.data)) {
          this.provisioning.data = [];
        }
        this.provisioning.data.push(response.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Save provisioning on server
   */
  saveProvisioning(prov, success, error) {
    this.restService.saveProvisioning(prov,
      (response) => {
        //Success
        angular.copy(response.data, prov);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  /**
   * Delete provisioning on server
   */
  deleteProvisioning(prov, success, error) {
    this.restService.deleteProvisioning(prov,
      (response) => {
        //Success
        this.provisioning.data.splice(this.provisioning.data.indexOf(prov), 1);
        success(this.provisioning.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  //#### AVAILABILITY RULES ####

  /**
   * Get all availability rules
   */
  getAvailabilityRules(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.availability.rules)) {
      this.availability.rules = [];
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadAvailabilityRules(success, error);
    } else {
      success(this.availability.rules);
    }
  };

  /**
   * Load weekly availability
   */
  loadWeeklyAvailability(person, kw, year, success, error) {
    this.restService.loadWeeklyAvailability(person, kw, year,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Clears the availability future cache
   */
  clearFutureCache() {
    this.availability.future = {}
  };

  /**
   * Load weekly availability
   */
  loadWeeklyAvailabilityByDate(person, date, success, error) {
    if (angular.isDefined(this.availability.future[person.id])) {

      if (angular.isDefined(this.availability.futureDate) && this.availability.futureDate.getTime() === date.getTime()) {
        // Use data from cache
        success(this.availability.future[person.id]);
        return;
      } else {
        this.availability.future = {};
      }
    }
    this.availability.futureDate = date;

    this.restService.loadWeeklyAvailabilityByDate(person, date.getTime(),
      (response) => {
        //Success
        this.availability.future[person.id] = response.data;
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  }

  getWeeklyAvailability() {
    return this.availability.future;
  }

  /**
   * Load monthly availability
   */
  loadMonthlyAvailability(person, month, year, success, error) {
    if (angular.isUndefined(person)) {
      return;
    }
    this.restService.loadMonthlyAvailability(person, month, year,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Fetch availability rules from server
   */
  loadAvailabilityRules(success, error) {
    this.$log.debug('Loading rules from server for first time...');
    this.restService.loadAvailabilityRules((response) => {
      //Success
      this.availability.rules = response.data;
      success(this.availability.rules);
    }, (response) => {
      //Failed
      this.availability.rules = undefined;
      error(response);
    });
  };


  /**
   * Create availability rule on server
   */
  createAvailabilityRule(rule, success, error) {
    var service = this;

    this.restService.createAvailabilityRule(rule,
      (response) => {
        //Success
        if (angular.isUndefined(this.availability.rules)) {
          this.availability.rules = [];
        }
        this.availability.rules.push(response.data);
        this.$rootScope.$emit('rules.updated', this.availability.rules);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Save availability rule on server
   */
  saveAvailabilityRule(rule, success, error) {
    var service = this;
    this.restService.saveAvailabilityRule(rule,
      (response) => {
        //Success

        let filters = this.availability.rules.filter(element => element.id === rule.id);

        if (filters.length === 1) {
          angular.copy(response.data, filters[0]);
        }
        this.$rootScope.$emit('rules.updated', this.availability.rules);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  /**
   * Delete provisioning on server
   */
  deleteAvailabilityRule(rule, success, error) {
    var service = this;
    this.restService.deleteAvailabilityRule(rule,
      (response) => {
        //Success

        let filters = this.availability.rules.filter(element => element.id === rule.id);

        if (filters.length === 1) {
          let indexToDelete = this.availability.rules.indexOf(filters[0]);
          this.availability.rules.splice(indexToDelete, 1);
        } else {
          service.$log.debug("Could not delete availability rule from internal list!");
        }

        this.$rootScope.$emit('rules.updated', this.availability.rules);
        success(this.availability.rules);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  // ### API KEYS ###

  /**
   * Get all API keys
   */
  getApiKeys(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.availability.apiKeys)) {
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadApiKeys(success, error);
    } else {
      success(this.availability.apiKeys);
    }
  };

  /**
   * Fetch API keys from server
   */
  loadApiKeys(success, error) {
    this.$log.debug('Loading api keys from server for first time...');
    this.restService.loadApiKeys((response) => {
      //Success
      this.availability.apiKeys = response.data;
      success(this.availability.apiKeys);
    }, (errorResponse) => {
      //Failed
      this.availability.apiKeys = undefined;
      error(errorResponse);
    });
  };


  /**
   * Create API key on server
   */
  createApiKey(key, success, error) {
    this.restService.createApiKey(key,
      (response) => {
        //Success
        if (angular.isUndefined(this.availability.apiKeys)) {
          this.availability.apiKeys = [];
        }
        this.availability.apiKeys.push(response.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Save API key on server
   */
  saveApiKey(key, success, error) {
    this.restService.saveApiKey(key,
      (response) => {
        //Success
        angular.copy(response.data, key);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  /**
   * Delete API key on server
   */
  deleteApiKey(key, success, error) {
    this.restService.deleteApiKey(key,
      (response) => {
        //Success
        this.availability.apiKeys.splice(this.availability.apiKeys.indexOf(key), 1);
        success(this.availability.apiKeys);
      },
      (response) => {
        //Failed
        error(response);
      });
  };



  //#### VEHICLES ####

  /**
   * Get all vehicles
   */
  getVehicles(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.vehicles.data)) {
      this.$log.debug('Loading vehicles from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadVehicles(success, error);
    } else {
      success(this.vehicles.data);
    }
  };

  /**
   * Get all vehicles
   */
  getVehiclesPaginated(forceReload, currentPage, limit, filter, ordering, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.vehiclesPaginated.data)) {
      this.$log.debug('Loading vehicles from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadVehiclesPaginated(currentPage, limit, filter, ordering, success, error);
    } else {
      success(this.vehiclesPaginated.data);
    }
  };

  getVehicleForVehicleId(vehicleId) {
    var vehicle;
    this.vehicles.data.forEach(v => {
      if (v.id === vehicleId) {
        vehicle = v;
      }
    });
    return vehicle;
  }

  /**
   * Get vehicles with status change within time range
   */
  getVehiclesWithStatusChange(start, end, success, error) {
    this.restService.getVehiclesWithStatusChange(start, end,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  }

  /**
   * Fetch vehicles from server
   */
  loadVehicles(success, error) {
    this.restService.loadVehicles(
      (response) => {
        //Success
        this.vehicles.data = response.data;
        success(this.vehicles.data);
      },
      (response) => {
        //Failed
        this.vehicles.data = undefined;
        error(response);
      });
  }

  loadVehiclesPaginated(currentPage, limit, filter, ordering, success, error) {
    this.restService.loadVehiclesPaginated(currentPage, limit, filter, ordering,
      (response) => {
        //Success
        this.vehiclesPaginated.data = response.data
        success(this.vehiclesPaginated.data);
      }, (errorReponse) => {
        //Error
        this.vehiclesPaginated.data = undefined;
        error(errorReponse);
      });
  }


  //#### AMOBILE MAPPING ####

  /**
   * Get all aMobile and vehicles mapping
   */
  getAmobileMapping(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.vehicles.mapping)) {
      this.$log.debug('Loading mapping from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadAmobileMapping(success, error);
    } else {
      success(this.vehicles.mapping);
    }
  };

  /**
   * Fetch aMobile mapping from server
   */
  loadAmobileMapping(success, error) {
    this.restService.loadAmobileMapping(
      (response) => {
        //Success
        this.vehicles.mapping = response.data;
        success(this.vehicles.mapping);
      },
      (response) => {
        //Failed
        this.vehicles.mapping = undefined;
        error(response);
      });
  };

  //#### PARKING POSITIONS ####

  /**
   * Get all parking positions
   */
  getParkingPositions(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.parkingPositions.data)) {
      this.$log.debug('Loading parkingPositions from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadParkingPositions(success, error);
    } else {
      success(this.parkingPositions.data);
    }
  };

  /**
   * Fetch parking positions from server
   */
  loadParkingPositions(success, error) {
    this.restService.loadParkingPositions(
      (response) => {
        //Success
        this.parkingPositions.data = response.data;
        success(this.parkingPositions.data);
      },
      (response) => {
        //Failed
        this.parkingPositions.data = undefined;
        error(response);
      });
  };

  //#### RELAIS ####

  /**
   * Get all relais
   */
  getRelais(forceReload, filter, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.relais.data)) {
      this.$log.debug('Loading relais from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadRelais((data) => {
        if (filter) {
          var tmp = data.filter((toFilter) => {
            if (toFilter.group === 'RESERVED' || toFilter.group === 'GATE') {
              return false;
            }
            return true;
          });
          success(tmp);
        } else {
          success(this.relais.data);
        }
      }, error);
    } else {
      if (filter) {
        var tmp = this.relais.data.filter((toFilter) => {
          if (toFilter.group === 'RESERVED' || toFilter.group === 'GATE') {
            return false;
          }
          return true;
        });
        success(tmp);
      } else {
        success(this.relais.data);
      }
    }
  };

  /**
   * Fetch relais from server
   */
  loadRelais(success, error) {
    this.restService.loadRelais(
      (response) => {
        //Success
        this.relais.data = response.data;
        success(this.relais.data);
      },
      (response) => {
        //Failed
        this.relais.data = undefined;
        error(response);
      });
  };

  /**
   * Get all relais from server
   */
  getAllRelais(success, error) {
    this.restService.getAllRelais(
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Load timeplan for vehicle
   */
  loadTimesForVehicle(vehicle, success, error) {
    this.restService.loadTimesForVehicle(vehicle,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  /**
   * Load timeplan for parking position
   */
  loadTimesForParkingPosition(parkingPosition, success, error) {
    this.restService.loadTimesForParkingPosition(parkingPosition,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Update timeplan for vehicle
   */
  updateTimesForVehicle(vehicle, timeplan, success, error) {
    timeplan.overwriteImpuls = vehicle.overwriteImpuls;
    this.restService.updateTimesForVehicle(vehicle, timeplan,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Update timeplan for parking position
   */
  updateTimesForParkingPosition(parkingPosition, timeplan, success, error) {
    this.restService.updateTimesForParkingPosition(parkingPosition, timeplan,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Update the vehicles status
   */
  updateVehicleStatus(vehicle, status, success, error) {
    this.restService.updateVehicleStatus(vehicle, status,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  getVehicleFaults(vehicle, success, error) {
    this.restService.getVehicleFaults(vehicle, (response) => {

      // Count active faults
      var numberOfFaultMessages = 0;
      response.data.forEach(fault => {
        if (fault.vehicleFaultState === 'ACTIVE') {
          numberOfFaultMessages++;
        }
      });

      vehicle.numberOfFaultMessages = numberOfFaultMessages;
      success(response.data);
    },
      (response) => {
        //Failed
        error(response);
      });
  }

  addVehicleFault(vehicle, fault, success, error) {
    this.restService.addVehicleFault(vehicle, fault, (response) => {
      vehicle.numberOfFaultMessages++;
      this.$rootScope.$emit('vehiclefault.change.' + vehicle.id, vehicle.numberOfFaultMessages);
      success(response.data);
    },
      (response) => {
        //Failed
        error(response);
      });
  }

  updateVehicleFault(vehicle, fault, success, error) {
    this.restService.updateVehicleFault(vehicle, fault, (response) => {
      success(response.data);
    }, (response) => {
      //Failed
      error(response);
    });
  }

  deleteVehicleFault(vehicle, fault, success, error) {
    this.restService.deleteVehicleFault(vehicle, fault, (response) => {
      // Update listener
      vehicle.numberOfFaultMessages--;
      this.$rootScope.$emit('vehiclefault.change.' + vehicle.id, vehicle.numberOfFaultMessages);
      success(response.data);
    },
      (response) => {
        //Failed
        error(response);
      });
  }

  /**
   * Add a new person
   */
  addPerson(displayName, lastName, firstName, email, fosId, success, error) {
    this.restService.addPerson(displayName, lastName, firstName, email, fosId,
      (response) => {
        this.$rootScope.$broadcast('change.addressbook');

        success(response.data);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * Change the state of a person
   */
  changePersonState(person, request, success, error) {
    this.restService.changePersonState(person, request,
      (response) => {
        this.$rootScope.$broadcast('change.addressbook');
        success(response.data);
      },
      (response) => {
        //Error occured
        error(response);
      });
  }
  /**
   * Add a new AM4 or new AMobile
   */
  addAM4(data, success, error) {
    this.restService.addAM4(data,
      (response) => {
        success();
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * Delete existing AM
   */
  deleteAM(am, isAmobile, success, error) {
    this.restService.deleteAMById(am.id,
      (response) => {
        success();
      },
      (response) => {
        //Error occured
        error(response);
      });
  };


  /**
   * Edit existing AM
   */
  editAM(am, success, error) {
    this.restService.editAM(am,
      (response) => {
        success(response.data);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };
  /**
   * Save a single person
   */
  savePerson(person, success, error) {
    //Remove property isExpanded
    delete person.isExpanded;
    person.displayName = person.lastName + ' ' + person.firstName;
    this.restService.savePerson(person,
      (response) => {
        success(response.data);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  //#### FUNCTIONS and GROUPS ####

  /**
   * Add a new function
   */
  addFunction(newFunction, success, error) {
    this.restService.addFunction(newFunction,
      (response) => {
        this.availability.data.functions = response.data;
        success(this.availability.data.functions);
      },
      (response) => {
        error(response);
      });
  };

  /**
   * Delete an existing function
   */
  deleteFunction(functionToDelete, success, error) {
    this.restService.deleteFunction(functionToDelete,
      (response) => {
        this.availability.data.functions = response.data;
        success(this.availability.data.functions);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * Add a new group
   */
  addGroup(newGroup, success, error) {
    this.restService.addGroup(newGroup,
      (response) => {
        this.availability.data.groups = response.data;
        success(this.availability.data.groups);
      },
      (response) => {
        error(response);
      });
  };

  /**
   * Delete an existing group
   */
  deleteGroup(groupToDelete, success, error) {
    this.restService.deleteGroup(groupToDelete,
      (response) => {
        this.availability.data.groups = response.data;
        success(this.availability.data.groups);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * return the roadblock types
   */
  getRoadblockTypes() {
    return this.roadblockTypes;
  }

  getRoadblockStates() {
    return this.roadblockStates;
  }


  getRoadblockAccesses() {
    return this.roadblockAccesses;
  }

  getVehicleFaultStates() {
    return this.vehicleFaultStates;
  }

  getRoadblockMapTripTypes() {
    return this.roadblockMapTripTypes;
  }

  getRoadblockMapTripDirections() {
    return this.roadblockMapTripDirections;
  }

  //#### PLUGINS ####
  /**
   * Load plugins from server
   */
  getPlugins(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.plugins) || angular.isUndefined(this.plugins.data)) {
      this.$log.debug('Loading plugins from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadPlugins(success, error);
    } else {
      success(this.plugins.data);
    }
  };

  /**
   * Fetch plugins from server
   */
  loadPlugins(success, error) {
    this.restService.loadPlugins(
      (response) => {
        //Success
        var data = {};
        for (var i = 0; i < response.data.length; i++) {
          var plugin = response.data[i];
          if (data[plugin.category] === undefined) {
            data[plugin.category] = [];
          }
          data[plugin.category].push(plugin);
        }

        this.plugins = data;
        success(this.plugins);
      },
      (response) => {
        //Failed
        this.plugins = undefined;
        error(response);
      });
  };
  //#### TEMPLATES ####
  /**
   * Load templates from server
   */
  getPipelineTemplates(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.templates.pipeline)) {
      this.$log.debug('Loading pipeline templates from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadPipelineTemplates(success, error);
    } else {
      success(this.templates.pipeline);
    }
  };

  /**
   * Fetch templates from server
   */
  loadPipelineTemplates(success, error) {
    this.restService.getPipelineTemplates(
      (response) => {
        //Success
        this.templates.pipeline = response.data;
        success(this.templates.pipeline);
      },
      (response) => {
        //Failed
        this.templates.pipeline = undefined;
        error(response);
      });
  };

  /**
   * Load alarm templates from server
   */
  getAlarmTemplates(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.templates.alarms)) {
      this.$log.debug('Loading alarm templates from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadAlarmTemplates(success, error);
    } else {
      success(this.templates.alarms);
    }
  };

  /**
   * Fetch alarm templates from server
   */
  loadAlarmTemplates(success, error) {
    this.restService.getAlarmTemplates(
      (response) => {
        //Success
        this.templates.alarms = response.data;
        success(this.templates.alarms);
      },
      (response) => {
        //Failed
        this.templates.alarms = undefined;
        error(response);
      });
  };

  /**
   * Add a new alarm template
   */
  addAlarmTemplate(data, success, error) {
    this.restService.addAlarmTemplate(data, (response) => {
      if (angular.isUndefined(this.templates.alarms) || this.templates.alarms === null) {
        this.templates.alarms = [];
      }
      this.templates.alarms.push(response.data);
      success(this.templates.alarms);
    }, (response) => {
      //Error occured
      error(response);
    });
  };

  /**
   * Update existing alarm template
   */
  updateAlarmTemplate(data, success, error) {
    this.restService.updateAlarmTemplate(data, (response) => {
      success(this.templates.alarms);
    }, (response) => {
      //Error occured
      error(response);
    });
  };

  /**
   * Delete a alarm template by id
   */
  deleteAlarmTemplateById(template, success, error) {
    this.restService.deleteAlarmTemplateById(template.id, (response) => {
      this.$rootScope.$emit('grid.updated', this.templates.grid);
      success(this.templates.alarms);
    }, (response) => {
      error(response);
    });
  };

  /**
   * Delete a alarm template grid by id
   */
  deleteAlarmTemplateGridById(grid, success, error) {
    this.restService.deleteAlarmTemplateGridById(grid.id, (response) => {
      this.templates.grid.splice(this.templates.grid.indexOf(grid), 1);
      this.$rootScope.$emit('grid.updated', this.templates.grid);
      success(this.templates.grid);
    }, (response) => {
      error(response);
    });
  };


  /**
   * Add a new alarm template grid
   */
  addAlarmTemplateGrid(data, success, error) {
    this.restService.addAlarmTemplateGrid(data,
      (response) => {
        if (angular.isUndefined(this.templates.grid) || this.templates.grid === null) {
          this.templates.grid = [];
        }
        this.templates.grid.push(response.data);
        this.$rootScope.$emit('grid.updated', this.templates.grid);
        success(this.templates.grid);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * Update existing alarm template
   */
  updateAlarmTemplateGrid(data, success, error) {
    this.restService.updateAlarmTemplateGrid(data,
      (response) => {
        success(this.templates.grid);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * Add a new pipeline template
   */
  addPipelineTemplate(name, description, pipeline, success, error) {
    this.restService.addPipelineTemplate(name, description, pipeline,
      (response) => {
        if (angular.isUndefined(this.templates.pipeline)) {
          this.templates.pipeline = [];
        }
        this.templates.pipeline.push(response.data);
        success(response.data);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * Gets a pipeline by template id
   */
  getPipelineTemplateById(template, success, error) {
    this.restService.getPipelineTemplateById(template.id, (response) => {
      success(response.data);
    }, (response) => {
      error(response);
    });
  };

  /**
   * Delete a pipeline by template id
   */
  deletePipelineTemplateById(template, success, error) {
    this.restService.deletePipelineTemplateById(template.id, (response) => {
      this.templates.pipeline = response.data;
      success(this.templates.pipeline);
    }, (response) => {
      error(response);
    });
  };

  /**
   * Load plugin templates from server
   */
  getPluginTemplates(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.templates.plugins)) {
      this.$log.debug('Loading plugin templates from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadPluginTemplates(success, error);
    } else {
      success(this.templates.plugins);
    }
  };

  /**
   * Fetch plugin templates from server
   */
  loadPluginTemplates(success, error) {
    this.restService.getPluginTemplates(
      (response) => {
        //Success
        this.templates.plugins = response.data;
        success(this.templates.plugins);
      },
      (response) => {
        //Failed
        this.templates.plugins = undefined;
        error(response);
      });
  };


  /**
   * Delete a plugin by template id
   */
  deletePluginTemplateById(template, success, error) {
    this.restService.deletePluginTemplateById(template.id,
      (response) => {
        this.loadPluginTemplates(success, error);
      },
      (response) => {
        error(response);
      });
  };

  /**
   * Update user settings
   */
  updateUserSettings(settings, success, error) {
    this.restService.updateUserSettings(settings,
      (response) => {
        success(response.data);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };


  /**
   * Add a plugin template as a plugin
   */
  addPluginTemplateToPipeline(template, success, error) {
    this.restService.createPluginFromTemplate(template.id,
      (response) => {
        success(response.data);
      },
      (response) => {
        //Error occured
        error(response);
      });
  };

  /**
   * Add a new plugin template
   */
  addPluginTemplate(description, plugin, success, error) {
    this.restService.addPluginTemplate(description, plugin, (response) => {
      if (angular.isUndefined(this.templates.plugins)) {
        //Templates not yet loaded
        this.loadPluginTemplates(() => {
          //All templates loaded
          success(response.data);
        }, (err) => {
          error(err);
        });
      } else {
        //Templates loaded before
        this.templates.plugins.push(response.data);
        success(response.data);
      }
    }, (response) => {
      error(response);
    });
  };


  /**
   * Update plugin template
   */
  updatePluginTemplate(plugin, success, error) {
    this.restService.updatePluginTemplate(plugin,
      (response) => {
        success(response.data);
      },
      (response) => {
        error(response);
      });
  };


  //#### ALARMOBJECTS ####

  /**
   * Get all alarm objects
   */
  getAllAlarmObjects(page, limit, forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.alarmObjects.data)) {
      this.$log.debug('Loading alarm objects from server for first time...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadAllAlarmObjects(page, limit, success, error);
    } else {
      success(alarmObjects);
    }
  };


  /**
   * Get single alarm object
   */
  getAlarmObjectById(id, success, error) {
    this.restService.getAlarmObjectById(id,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Fetch alarm objects from server
   */
  loadAllAlarmObjects(page, limit, success, error) {
    this.restService.getAllAlarmObjects(page, limit,
      (response) => {
        //Success
        this.alarmObjects.data = response.data.data;
        success(response.data);
      },
      (response) => {
        //Failed
        this.alarmObjects.data = undefined;
        error(response);
      });
  };

  /**
   * Search for objects
   * @param {} filterString
   * @param {*} success
   * @param {*} error
   */
  searchForAlarmObject(page, limit, filterString, queryFilter, sort, descending, success, error) {
    this.restService.searchForAlarmObjectAsSlice(page, limit, filterString, queryFilter, sort, descending).then(result=>{
      this.alarmObjects.data= result.alarmObjects;
      success(result);

    }).catch(errorResp=>{
      error(errorResp)
      }
    )
  }


  /**
   * Create alarm object on server
   */
  addAlarmObject(name, success, error) {
    this.restService.addAlarmObject(name,
      (response) => {
        //Success
        if (angular.isUndefined(this.alarmObjects.data)) {
          this.alarmObjects.data = [];
        }
        this.alarmObjects.data.push(response.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Add a new sub object
   */
  addSubObject(name, id, success, error) {
    this.restService.addSubObject(name, id,
      (response) => {
        //Success
        this.alarmObjects.data.push(response.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  }

  /**
   * Save alarm object on server
   */
  updateAlarmObject(alarmObject, success, error) {
    this.restService.updateAlarmObject(alarmObject,
      (response) => {
        //Success
        angular.copy(response.data, alarmObject);

        // Update object in array
        for (var i = 0; i < this.alarmObjects.data.length; i++) {
          var o = this.alarmObjects.data[i];
          if (o.id === alarmObject.id) {
            o.ident = alarmObject.ident;
            o.level = alarmObject.level;
            o.name = alarmObject.name;
            o.state = alarmObject.state;
            o.type = alarmObject.type;
            break;
          }
        }

        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  getEvents(forceReload, range, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.events.data) || angular.isUndefined(this.events.range) || this.events.range !== range) {
      this.$log.debug('Loading events for dashboard from server...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      this.restService.getEvents(range, (response) => {
        this.events.data = response;
        this.events.range = range;
        success(this.events.data);
      }, (errorResponse) => {
        error(errorResponse);
      })
    } else {
      success(this.events.data)
    }
  }

  getShiftbookCategories(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.shiftbookCategories)) {
      this.$log.debug('Loading shiftbook categories for dashboard from server...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      this.restService.getShiftbookCategories((response) => {
        this.shiftbookCategories = response;
        success(this.shiftbookCategories);
      }, (errorResponse) => {
        error(errorResponse);
      })
    } else {
      success(this.shiftbookCategories);
    }
  }

  deleteShiftbookCategory(category, success, error) {
    this.restService.deleteShiftbookCategory(category, (response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse);
    })
  }

  editShiftbookCategory(category, success, error) {
    this.restService.editShiftbookCategory(category, (response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  createShiftbookCategory(categoryName, success, error) {
    this.restService.createShiftbookCategory(categoryName, (response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  getShiftbookCustomFields(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.shiftbookCustomFields)) {
      this.$log.debug('Loading shiftbook custom fields for dashboard from server...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      this.restService.getShiftbookCustomField((response) => {
        this.shiftbookCustomFields = response;
        success(this.shiftbookCustomFields);
      }, (errorResponse) => {
        error(errorResponse);
      })
    } else {
      success(this.shiftbookCustomFields);
    }
  }

  deleteShiftbookCustomField(field, success, error) {
    this.restService.deleteShiftbookCustomField(field, (response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse);
    })
  }

  editShiftbookCustomField(field, success, error) {
    this.restService.editShiftbookCustomField(field, (response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  createShiftbookCustomField(fieldName, success, error) {
    this.restService.createShiftbookCustomField(fieldName, (response) => {
      success(response);
    }, (errorResponse) => {
      error(errorResponse)
    })
  }

  getShiftbookRoadblocks(forceReload, start, end, onlyImportant, success, error) {

    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.shiftbookRoadblocks)) {
      this.$log.debug('Loading shiftbook roadblocks for dashboard from server...');
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      this.restService.getShiftbookRoadblocks(start, end, onlyImportant, (response) => {
        this.shiftbookRoadblocks = response
        success(this.shiftbookRoadblocks);
      }, (errorResponse) => {
        error(errorResponse);
      })
    } else {
      success(this.shiftbookRoadblocks);
    }
  }

  /**
   * Change password
   */
  changePassword(currentPw, newPw, success, error) {
    var data = {
      oldPassword: currentPw,
      newPassword: newPw
    };
    this.restService.changePassword(data,
      (response) => {
        success(response.data);
      },
      (response) => {
        error(response);
      });
  };

  changeAdminPassword(newPw, newPwR, success, error) {
    var data = {
      newPassword: newPw,
      newPasswordRepeat: newPwR
    };
    this.restService.changeAdminPassword(data,
      (response) => {
        success(response.data);
      },
      (response) => {
        error(response);
      });
  };

  /**
   * Delete existing sub object
   */
  deleteSubObject(parentId, subId, keepInDb, success, error) {

    this.restService.deleteSubObject(parentId, subId, keepInDb,
      (response) => {
        //Success
        var toDelete = -1;
        var alarmObject = undefined;
        for (var i = 0; i < this.alarmObjects.data.length; i++) {
          if (this.alarmObjects.data[i].id === subId) {
            toDelete = i;
            alarmObject = this.alarmObjects.data[i];
            break;
          }
        }

        if (toDelete !== -1) {
          if (keepInDb) {
            alarmObject.subType = 'MAIN';
          } else {
            this.alarmObjects.data.splice(toDelete, 1);
          }
          this.$rootScope.$emit('objects.updated', this.alarmObjects.data);
          success(this.alarmObjects.data);
        }

      },
      (response) => {
        //Failed
        error(response);
      });

  }



  /**
   * Delete alarm object on server
   */
  deleteAlarmObjectById(alarmObject, success, error) {
    this.restService.deleteAlarmObjectById(alarmObject.id,
      (response) => {
        //Success
        var toDelete = [];

        for (var cntAlarmObject = 0; cntAlarmObject < this.alarmObjects.data.length; cntAlarmObject++) {
          if (this.alarmObjects.data[cntAlarmObject].id ===
            alarmObject.id) {
            toDelete.push(this.alarmObjects.data[cntAlarmObject]);
          }
          // Remove sub objects
          for (var cntSubObject = 0; cntSubObject < alarmObject.subObjects.length; cntSubObject++) {
            if (this.alarmObjects.data[cntAlarmObject].id ===
              alarmObject.subObjects[cntSubObject].id) {
              toDelete.push(this.alarmObjects.data[cntAlarmObject]);
            }
          }
        }
        for (var i = 0; i < toDelete.length; i++) {
          var objectToDelete = toDelete[i];
          this.alarmObjects.data.splice(this.alarmObjects.data.indexOf(objectToDelete), 1);
        }
        this.$rootScope.$emit('objects.updated', this.alarmObjects.data);
        success(this.alarmObjects.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Login as different user
   * @param {*} person
   * @param {*} success
   * @param {*} error
   */
  changeLoginAsAdmin(username, success, error) {
    this.restService.changeLoginAsAdmin(username,
      (response) => {
        this.logout();
        this.restService.setToken(response.data.token, true);

        this.loadAccount(true,() => {
          success(true);
        });


      },
      (response) => {
        error(response);
      });
  };

  switchBackToAdmin(account, success, error) {
    this.restService.getAdminToken(account.loggedInFromAdmin).then(((result)=>{
      this.logout();
      this.restService.setToken(result.token, false);
      this.loadAccount(account.loggedInFromAdmin,()=>{
        success(true);}, (errorResponseAccount)=>{
        this.logout();
        error(errorResponseAccount)
      });

    })).catch((errorResponse)=>{
        this.logout();
        error(errorResponse)
    });
  };

  /**
   * Login as different user
   * @param {*} person
   * @param {*} success
   * @param {*} error
   */
  changeLoginAsPerson(username, success, error) {
    this.restService.changeLoginAsPerson(username,
      (response) => {
        this.logout();
        this.restService.setToken(response.data.token, false);
        this.loadAccount(false,() => {
          success(true);
        });


      },
      (response) => {
        error(response);
      });
  };


  /**
   * Add document
   */
  uploadDocument(alarmObject, document, success, error) {

    this.restService.uploadDocument(alarmObject, document,
      (response) => {
        //Success
        //If file already exists, delete it
        var toDelete = -1;
        for (var i = 0; i < alarmObject.documents.length; i++) {
          if (alarmObject.documents[i].id === response.id) {
            toDelete = i;
            break;
          }
        }
        if (toDelete > -1) {
          alarmObject.documents.splice(toDelete, 1);
        }

        alarmObject.documents.push(response);
        success(response);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Delete an existing document
   * @param {*} alarmObject
   * @param {*} document
   * @param {*} success
   * @param {*} error
   */
  deleteDocument(alarmObject, document, success, error) {
    this.restService.deleteDocument(alarmObject, document,
      (response) => {
        //Success
        alarmObject.documents.splice(alarmObject.documents.indexOf(document), 1);
        success(alarmObject);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Delete an existing directioncard
   * @param {*} alarmObject
   * @param {*} directioncard
   * @param {*} success
   * @param {*} error
   */
  deleteDirectioncard(alarmObject, directioncard, success, error) {
    this.restService.deleteDirectioncard(alarmObject, directioncard, (response) => {
      //Success
      alarmObject.bma.directioncards.splice(alarmObject.bma.directioncards.indexOf(directioncard), 1);
      success(alarmObject);
    }, (response) => {
      //Failed
      error(response);
    });
  };

  // ### ROADBLOCKS ###
  /**
   * Get all roadblocks
   */
  getAllRoadblocks(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.roadblocks.data)) {
      this.$log.debug('Loading roadblocks from server for first time...');
      fetchFromServer = true;
    }
    if (fetchFromServer) {
      //Load
      this.loadAllRoadblocks(success, error);
    } else {
      success(this.roadblocks.data);
    }
  };

  /**
   * Fetch roadblocks from server
   */
  loadAllRoadblocks(success, error) {
    this.restService.getAllRoadblocks(
      (response) => {
        //Success
        this.roadblocks.data = response.data;
        this.$rootScope.$emit('roadblocks.updated', this.roadblocks.data);
        success(this.roadblocks.data);
      },
      (response) => {
        //Failed
        this.roadblocks.data = undefined;
        error(response);
      });
  };

  /**
   * Create roadblock on server
   */
  addRoadblock(name, success, error) {
    this.restService.addRoadblock(name,
      (response) => {
        //Success
        if (angular.isUndefined(this.roadblocks.data)) {
          this.roadblocks.data = [];
        }
        this.roadblocks.data.push(response.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * resend roadblocks
   */
  resendRoadblocks(success, error) {
    this.restService.resendRoadblocks(
      (response) => {
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Save roadblock on server
   */
  updateRoadblock(roadblock, sendNotification, success, error) {
    this.restService.updateRoadblock(roadblock, sendNotification, (response) => {
        //Success
        angular.copy(response.data, roadblock);
        this.$rootScope.$emit('roadblocks.updated', this.roadblocks.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  // ### Image assets ###
  /**
   * Get all image assets
   */
  getAllImageAssets(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.assets.data)) {
      this.$log.debug('Loading image assets from server for first time...');
      fetchFromServer = true;
    }
    if (fetchFromServer) {
      //Load
      this.loadAllImageAssets(success, error);
    } else {
      success(this.assets.data);
    }
  };

  /**
   * Fetch image assets from server
   */
  loadAllImageAssets(success, error) {
    this.restService.getAllImageAssets(
      (response) => {
        //Success
        this.assets.data = response.data;
        success(this.assets.data);
      },
      (response) => {
        //Failed
        this.assets.data = undefined;
        error(response);
      });
  };

  /**
   * Delete existing image asset
   */
  deleteImageAsset(asset, success, error) {
    this.restService.deleteImageAssetById(asset.id,
      () => {
        //Success
        this.assets.data.splice(this.assets.data.indexOf(asset), 1);
        success();
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  // POIs

  /**
   * Get all pois
   */
  getAllPOIs(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.pois.data)) {
      this.$log.debug('Loading POIs from server for first time...');
      fetchFromServer = true;
    }
    if (fetchFromServer) {
      //Load
      this.loadAllPOIs(success, error);
    } else {
      success(this.pois.data);
    }
  };

  /**
   * Fetch POIs from server
   */
  loadAllPOIs(success, error) {
    this.restService.getAllPOIs(
      (response) => {
        //Success
        this.pois.data = response.data;
        success(this.pois.data);
      },
      (response) => {
        //Failed
        this.pois.data = undefined;
        error(response);
      });
  };

  /**
   * Delete existing POI
   */
  deletePOI(poi, success, error) {
    this.restService.deletePOIById(poi.id,
      () => {
        //Success
        this.pois.data.splice(this.pois.data.indexOf(poi), 1);
        success();
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Delete existing POI from Alarm Object
   */
  deletePOIFromAlarmObject(poi, success, error) {
    this.restService.deletePOIById(poi.id,
      () => {
        //Success
        success();
      },
      (response) => {
        //Failed
        error(response);
      });
  };




  /**
   * Create poi on server
   */
  addPOI(name, assignedObject, success, error) {
    this.restService.addPOI(name, assignedObject,
      (response) => {
        //Success
        if (angular.isUndefined(this.pois.data)) {
          this.pois.data = [];
        }
        this.pois.data.push(response.data);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Save POI on server
   */
  updatePOI(poi, success, error) {
    this.restService.updatePOI(poi,
      (response) => {
        //Success
        angular.copy(response.data, poi);
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };


  // Alarm Template GRID

  /**
   * Fetch parking positions from server
   */
  loadAlarmTemplateGrids(success, error) {
    this.restService.loadAlarmTemplateGrids(
      (response) => {
        //Success
        this.templates.grid = response.data;
        success(this.templates.grid);
      },
      (response) => {
        //Failed
        this.templates.grid = undefined;
        error(response);
      });
  };

  /**
   * Regenerate self registration key
   */
  regenerateSelfRegistrationKey(success, error) {
    var self = this;
    this.restService.regenerateSelfRegistrationKey(
      (response) => {
        //Success
        self.account.selfRegisterKey = response.data.key;
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Load alarm template grid from server
   */
  getAlarmTemplateGrids(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.templates.grid)) {
      this.$log.debug('Loading alarm template grid from server for first time...');
      fetchFromServer = true;
    }
    if (fetchFromServer) {
      this.loadAlarmTemplateGrids(success, error);
    } else {
      success(this.templates.grid);
    }
  };


  /**
   * Load alarm template grid from server
   */
  updateAlarmTemplateGrid(grid, success, error) {
    this.restService.updateAlarmTemplateGrid(grid,
      () => {
        //Success
        success(this.templates.grid);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  /**
   * Delete roadblock on server
   */
  deleteRoadblockById(roadblock, success, error) {
    this.restService.deleteRoadblockById(roadblock.id,
      () => {
        //Success
        this.roadblocks.data.splice(this.roadblocks.data.indexOf(roadblock), 1);
        this.$rootScope.$emit('roadblocks.updated', this.roadblocks.data);
        success(this.roadblocks.data);
      },
      (err) => {
        error(err);
      });
  };

  /**
   * Get Log files
   */
  getLogsInFolder(currentDir, dateFilter, success, error) {
    this.restService.getLogsInFolder(currentDir, dateFilter,
      (response) => {
        //Success
        success(response.data);
      },
      (err) => {
        error(err);
      });
  };
  /**
   * Get all layers
   */
  getLayers(forceReload, success, error) {
    var fetchFromServer = forceReload;

    if (angular.isUndefined(this.layers)) {
      fetchFromServer = true;
    }

    if (fetchFromServer) {
      //Load
      this.loadLayers(success, error);
    } else {
      success(this.layers);
    }
  };

  /**
   * Fetch layers from server
   */
  loadLayers(success, error) {
    this.$log.debug('Loading layers from server...');
    this.restService.loadLayers((response) => {
      //Success
      this.layers = response.data;
      success(this.layers);
    }, (errorResponse) => {
      //Failed
      this.layers = undefined;
      error(errorResponse);
    });
  };

  /**
   * Delete layer from server
   */
  deleteLayerByName(layer, success, error) {
    this.restService.deleteLayerByName(layer.name,
      () => {
        //Success
        this.layers.splice(this.layers.indexOf(layer), 1);

        this.$rootScope.$emit('layers.updated', this.layers);

        success(this.layers);
      },
      (err) => {
        error(err);
      });
  };

  /**
   * Update layer on server
   */
  updateLayer(layer, success, error) {
    this.restService.updateLayer(layer,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };
  /**
   * Add a new unit
   */
  addUnit(name, success, error) {
    var request = {
      name: name
    };
    this.restService.addUnit(request,
      (response) => {
        //Success
        success(response.data);
      },
      (response) => {
        //Failed
        error(response);
      });
  };

  getNumberOfAlarmsForAllUsers(success, error) {
    this.restService.getNumberOfAlarmsForAllUsers(response => {
      success(response);
    }, errorResponse => {
      error(errorResponse)
    })
  }
}
