import { IScope } from "angular";
import RestService from "../../../../services/rest.service";
import ErrorService from "../../../../services/error.service";
import { HttpMetrics, MqttStatus } from "../../../../data/admin.data";

require("./admin.connections.component.scss")

export default class AdminConnectionsComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
      this.restrict = 'E';
      this.template = require('./admin.connections.component.html');
      this.scope = {};
      this.controller = AdminConnectionsController;
      this.controllerAs = 'ctrl';
      this.bindToController = true;
    }
}

class AdminConnectionsController {
    public connections: MqttStatus[] = [];
    public metrics: HttpMetrics[] = [];


    constructor(private readonly restService: RestService,
        private readonly $scope: IScope,
        private readonly errorService: ErrorService) {
        this.load();
    }


    private async load() {
        try {
            this.connections = await this.restService.getConnections();
            this.metrics = await this.restService.getHttpMetrics();
        } catch (err) {
            this.errorService.notifyWithText(err.data.message);
        } finally {
            this.$scope.$applyAsync();
        }
    }

}